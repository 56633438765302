export default [
  {
    name: "Week 1",
    startDate: "2024-08-23",
    endDate: "2024-09-03",
  },
  {
    name: "Week 2",
    startDate: "2024-09-05",
    endDate: "2024-09-08",
  },
  {
    name: "Week 3",
    startDate: "2024-09-08",
    endDate: "2024-09-15",
  },
  {
    name: "Week 4",
    startDate: "2024-09-15",
    endDate: "2024-09-22",
  },
  {
    name: "Week 5",
    startDate: "2024-09-22",
    endDate: "2024-09-29",
  },
  {
    name: "Week 6",
    startDate: "2024-09-29",
    endDate: "2024-10-06",
  },
  {
    name: "Week 7",
    startDate: "2024-10-06",
    endDate: "2024-10-13",
  },
  {
    name: "Week 8",
    startDate: "2024-10-13",
    endDate: "2024-10-20",
  },
  {
    name: "Week 9",
    startDate: "2024-10-20",
    endDate: "2024-10-27",
  },
  {
    name: "Week 10",
    startDate: "2024-10-27",
    endDate: "2024-11-03",
  },
  {
    name: "Week 11",
    startDate: "2024-11-04",
    endDate: "2024-11-10",
  },
  {
    name: "Week 12",
    startDate: "2024-11-10",
    endDate: "2024-11-17",
  },
  {
    name: "Week 13",
    startDate: "2024-11-17",
    endDate: "2024-11-23",
  },
  {
    name: "Week 14",
    startDate: "2024-11-23",
    endDate: "2024-11-31",
  },
  {
    name: "Week 15",
    startDate: "2024-11-31",
    endDate: "2024-12-08",
  },
  {
    name: "Bowls",
    startDate: "2024-12-15",
    endDate: "2025-01-22",
  },
];
